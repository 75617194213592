@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat'");
@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Square+Peg&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova&family=Merriweather&family=Taviraj&family=Uchen&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Capriola&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900;1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courgette&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;600;700&display=swap");

html,
body {
  margin: 0;
  padding: 0;
  max-width: 100vw - 16px;
  max-height: 100vh;
}

body {
  margin: 0;
  font-family: Poppins, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 85%;
}

@media (max-width: 678px) {
  body {
    font-size: 75%;
  }
}
@media (max-width: 567px) {
  body {
    font-size: 66%;
  }
}

* {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #cbd6e2;
  font-size: 28px;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #cbd6e2;
  font-size: 28px;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #cbd6e2;
  font-size: 28px;
}

::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}
::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dfe8;
  border-radius: 20px;
}

.waveAnimation {
  animation-name: wave_hand;
  animation-duration: 2.5s; 
  animation-iteration-count: infinite; 
  transform-origin: 70% 70%;
}

@keyframes wave_hand {
  0% { transform: rotate( 0.0deg) }
 10% { transform: rotate(14.0deg) }
 20% { transform: rotate(-8.0deg) }
 30% { transform: rotate(14.0deg) }
 40% { transform: rotate(-4.0deg) }
 50% { transform: rotate(10.0deg) }
 60% { transform: rotate( 0.0deg) }
100% { transform: rotate( 0.0deg) }
}
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat');
@import url(https://fonts.googleapis.com/css2?family=Urbanist:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Square+Peg&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova&family=Merriweather&family=Taviraj&family=Uchen&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Capriola&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900;1000&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courgette&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  margin: 0;
  padding: 0;
  max-width: 100vw - 16px;
  max-height: 100vh;
}

body {
  margin: 0;
  font-family: Poppins, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 85%;
}

@media (max-width: 678px) {
  body {
    font-size: 75%;
  }
}
@media (max-width: 567px) {
  body {
    font-size: 66%;
  }
}

* {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #cbd6e2;
  font-size: 28px;
  opacity: 1; /* Firefox */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #cbd6e2;
  font-size: 28px;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #cbd6e2;
  font-size: 28px;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #cbd6e2;
  font-size: 28px;
}

::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}
::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dfe8;
  border-radius: 20px;
}

.waveAnimation {
  -webkit-animation-name: wave_hand;
          animation-name: wave_hand;
  -webkit-animation-duration: 2.5s;
          animation-duration: 2.5s; 
  -webkit-animation-iteration-count: infinite; 
          animation-iteration-count: infinite; 
  -webkit-transform-origin: 70% 70%; 
          transform-origin: 70% 70%;
}

@-webkit-keyframes wave_hand {
  0% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }
 10% { -webkit-transform: rotate(14.0deg); transform: rotate(14.0deg) }
 20% { -webkit-transform: rotate(-8.0deg); transform: rotate(-8.0deg) }
 30% { -webkit-transform: rotate(14.0deg); transform: rotate(14.0deg) }
 40% { -webkit-transform: rotate(-4.0deg); transform: rotate(-4.0deg) }
 50% { -webkit-transform: rotate(10.0deg); transform: rotate(10.0deg) }
 60% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }
100% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }
}

@keyframes wave_hand {
  0% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }
 10% { -webkit-transform: rotate(14.0deg); transform: rotate(14.0deg) }
 20% { -webkit-transform: rotate(-8.0deg); transform: rotate(-8.0deg) }
 30% { -webkit-transform: rotate(14.0deg); transform: rotate(14.0deg) }
 40% { -webkit-transform: rotate(-4.0deg); transform: rotate(-4.0deg) }
 50% { -webkit-transform: rotate(10.0deg); transform: rotate(10.0deg) }
 60% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }
100% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }
}
